import styled from 'styled-components';
import { ThemeType } from '../../types';
import Block from '../../blocks/Block/styles';
import { TIME_OFFSET, ELEMENT_SIZE } from '../../constants/timetable';

export const Filter = styled.div(
  ({ theme, active }: ThemeType & { active: boolean }) => `
    height: 48px;
    width: 100%;
    border-top: 2px solid ${theme.colors.darkGrey};
    border-bottom: 2px solid ${theme.colors.darkGrey};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 800;

    &:hover {
      border-style: solid;
      border-width: 2px;
      border-image-slice: 1;
    }

    ${
      active
        ? `
          color: ${theme.colors.fullBlack};
          background-image: ${theme.linearGradient.blueGreenBottom};
          border-image-source: ${theme.linearGradient.blueGreenBottom};

          &:hover {
            background-image: ${theme.linearGradient.blueGreenTop};
            border-image-source: ${theme.linearGradient.blueGreenTop};
          }
          `
        : `
          &:hover {
            color: ${theme.colors.green};
            border-image-source: ${theme.linearGradient.blueGreenBottom};
          }
        `
    }
  `,
);

export const HorizontalMessage = styled.div(
  ({ theme, text }: ThemeType & { text: string }) => `
    transform: translateX(-24px);
    left: -100px;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.green};
    font-size: 12px;
    font-weight: 900;
    text-align: center;

    &:before {
      content: '${text}';
    }
`,
);

export const VerticalMessage = styled(HorizontalMessage)(
  ({ text }: ThemeType & { text: string }) => `
    width: 50px;
    left: -50px;

    &:before {
      content: '${text}';
      display: flex;
      align-items: center;
      position: absolute;
      transform-origin: bottom left;
      transform: rotate(90deg);
      height: 50px;
      top: -50px;
      transform-origin: bottom left;
      transform: rotate(90deg);
      width: 72px;
      left: 0;
    }
  `,
);

export const Stage = styled.div(
  ({ theme, count }: ThemeType & { count: number }) => `
    flex-grow: ${count};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 900;
    line-height: 1;
    padding: 0 8px;
    text-transform: uppercase;
    position: relative;
    ${theme.mobileView} {
      font-size: 10px;
    }
    span {
      transform: translateY(-50%);
      width: 100%;
      ${theme.mobileView} {
        word-break: break-word;
      }
    }
  `,
);

export const Days = styled.div(
  ({ theme }: ThemeType) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 1 768px;

    span {
      flex: 0;
      font-size: 20px;
      font-weight: 900;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.5);
      text-decoration: none;
      cursor: pointer;
      border-bottom: 5px solid transparent;
      transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
      ${theme.mobileView} {
        font-size:14px;
      }
      &:hover,
      &.active {
        color: rgba(255, 255, 255, 1);
        border-color: ${theme.colors.pink};
      }
    }
  `,
);

export const Sidebar = styled.div(
  ({ theme }: ThemeType) => `
    border-top: 2px solid ${theme.colors.darkGrey};
    border-bottom: 2px solid ${theme.colors.darkGrey};
    display: flex;
    position: sticky;
    background: ${theme.colors.fullBlack};
    flex-direction: column;
    justify-content: space-around;
    z-index: 1;
    text-align: center;
    position: absolute;
    left: 0;
    top: ${ELEMENT_SIZE.TIME_HEIGHT}px;
    bottom: ${ELEMENT_SIZE.TIME_HEIGHT + 2}px;
    width: ${ELEMENT_SIZE.SIDEBAR_WIDTH}px;

    ${theme.mobileView}  {
      width: ${ELEMENT_SIZE.SIDEBAR_WIDTH / 1.5}px;
    }
  `,
);

export const Times = styled.div(
  ({ theme }: ThemeType) => `
    margin-left: ${ELEMENT_SIZE.SIDEBAR_WIDTH}px;
    white-space: nowrap;
    height: ${ELEMENT_SIZE.TIME_HEIGHT}px;
    display: flex;
    align-items: center;

    ${theme.mobileView}  {
      margin-left: ${ELEMENT_SIZE.SIDEBAR_WIDTH / 1.5}px;
    }
    
    span {
      display: inline-block;
      color: ${theme.colors.white};
      text-transform: uppercase;
      font-weight: 800;
      text-align: center;
      width: ${TIME_OFFSET.ONE_HOUR}px;
      transform: translateX(-50%);

      ${theme.mobileView} {
        width: ${TIME_OFFSET.ONE_HOUR / 2}px;
      }
    }
  `,
);

export default styled(Block)(
  ({ theme }: ThemeType) => `
    padding: 0 !important;
    border-top: 2px solid ${theme.colors.darkGrey};
  `,
);

export const Header = styled.div(
  ({ theme }: ThemeType) => `
    flex: 1;
    display: flex;
    padding: 20px;
    justify-content: center;
  `,
);

export const Scrollable = styled.div(
  ({ theme }: ThemeType) => `
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;

    .sessions {
      padding-left: ${ELEMENT_SIZE.SIDEBAR_WIDTH}px;
      display: flex;

      ${theme.mobileView}  {
        padding-left: ${ELEMENT_SIZE.SIDEBAR_WIDTH / 1.5}px;
      }
    }
  `,
);

export const Timeline = styled.div(
  ({ theme }: ThemeType) => `
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    width: 100%;
  `,
);

export const Session = styled.div(
  ({ theme, duration, offset, isVisible }: ThemeType & { isVisible: boolean; duration: number; offset: number }) => `
    padding: 8px;
    height: 100%;
    border-style: solid;
    border-width: 1px;
    border-image-source: ${theme.linearGradient.blueGreenBottom};
    border-image-slice: 1;
    min-width: ${duration * TIME_OFFSET.ONE_HOUR}px;
    max-width: ${duration * TIME_OFFSET.ONE_HOUR}px;
    margin-left: ${offset * TIME_OFFSET.ONE_HOUR}px; 
    cursor: pointer;
    transition: all .2s ease-in-out;

    background-color: rgba(0, 0, 0, 0.75);
    opacity: ${isVisible === false ? '.15' : '1'};

    ${theme.mobileView} {
      min-width: ${(duration * TIME_OFFSET.ONE_HOUR) / 2}px;
      max-width: ${(duration * TIME_OFFSET.ONE_HOUR) / 2}px;
      margin-left: ${(offset * TIME_OFFSET.ONE_HOUR) / 2}px; 
    }
    
    &:hover {
      background-color: ${theme.colors.pink};
      border: 1px solid ${theme.colors.pink};
    }

    .name, 
    .time {
      line-height: 1;
      font-size: 14px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name {
      font-weight: 900;
      margin-bottom: 8px;
      text-transform: uppercase;      
    }

    .time {
      opacity: 0.5;
      line-height: 1.71;
      letter-spacing: 0.2px;
    }
  `,
);

export const Column = styled.div(
  ({ theme, duration }: ThemeType & { duration: number }) => `
    position: relative;
    background-color: ${theme.colors.fullBlack};
    border-top: 2px solid ${theme.colors.darkGrey};
    border-bottom: 2px solid ${theme.colors.darkGrey};
    flex: 0 0 ${duration * TIME_OFFSET.ONE_HOUR + TIME_OFFSET.ONE_HOUR + 2}px;
    padding-right: ${TIME_OFFSET.ONE_HOUR}px;

    ${theme.mobileView} {
      flex: 0 0 ${(duration * TIME_OFFSET.ONE_HOUR) / 2 + TIME_OFFSET.ONE_HOUR / 2 + 2}px;
      padding-right: ${TIME_OFFSET.ONE_HOUR / 2}px;
    }

    &:after {
      content: '';
      width: ${TIME_OFFSET.ONE_HOUR}px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      background: repeating-linear-gradient(
        45deg, 
        rgba(255, 255, 255, 0.1), 
        rgba(255, 255, 255, 0.1) 20px,
        transparent 20px,
        transparent 40px
      );

      ${theme.mobileView} {
        width: ${TIME_OFFSET.ONE_HOUR / 2}px;
      }
    }
  `,
);

export const Track = styled.div(
  ({ theme }: ThemeType) => `
    flex: 0 0 100%;
    height: ${ELEMENT_SIZE.TRACK_HEIGHT}px;
    min-height: ${ELEMENT_SIZE.TRACK_HEIGHT}px;
    display: flex;
    flex-direction: row;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  `,
);

export const Row = styled.div(
  ({ theme }: ThemeType) => `
    display: flex;
    overflow: hidden;
    flex-direction: column;
    padding: 24px 0 24px 1px;
    min-height: 124px;
    background: repeating-linear-gradient(
      to right, 
      ${theme.colors.darkGrey} 0px, 
      ${theme.colors.darkGrey} 2px, 
      transparent 2px, 
      transparent ${TIME_OFFSET.ONE_HOUR}px
    );

      ${theme.mobileView} {
        background: repeating-linear-gradient(
          to right, 
          ${theme.colors.darkGrey} 0px, 
          ${theme.colors.darkGrey} 2px, 
          transparent 2px, 
          transparent ${TIME_OFFSET.ONE_HOUR / 2}px
        );
      }
    
    &:not(:last-of-type) {
      border-bottom: 2px solid ${theme.colors.darkGrey};
    }

    &:nth-of-type(odd) {
      background-color: rgba(255, 255, 255, 0.1);
    }

    ${Session} {
      position: relative;
    }
  `,
);
