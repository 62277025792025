export const TIME_CONSTANTS = {
  ONE_DAY: 24,
  ONE_HOUR: 60,
  HALF_HOUR: 30,
};

export const TIME_OFFSET = {
  ONE_HOUR: 240,
};

export const ELEMENT_SIZE = {
  SIDEBAR_WIDTH: 143,
  SESSION_HEIGHT: 80,
  TRACK_HEIGHT: 76,
  TIME_HEIGHT: 60,
};
