import React from 'react';
import CSSParallax from '../../components/CSSParallax';
import theme from '../../styles/theme';
import { StyledSection } from './styles';

const StarryBlack: React.FunctionComponent<any> = ({ children, isFirstSection, pageHasAnnouncement, noFooterBlocks, natural }) => (
  <StyledSection natural={natural}>
    <CSSParallax.Content isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement} natural={natural}>
      <CSSParallax.Dividers.Gradient color={theme.colors.fullBlack} height={30} />
      <CSSParallax.Padder height={7} />
      <CSSParallax.ContentInner>{children}</CSSParallax.ContentInner>
      <CSSParallax.Padder height={0} />
      {!noFooterBlocks && <CSSParallax.Dividers.BrushStroke variant={4} color={theme.colors.fullBlack} />}
    </CSSParallax.Content>
    <CSSParallax.Layer depth={2} natural={natural}>
      <CSSParallax.Greeblies.PinkSplatter1 top={0} flipX={true} />
      <CSSParallax.Greeblies.BlueSplatter2 top={0} right={0} />
    </CSSParallax.Layer>
  </StyledSection>
);

export default StarryBlack;
