import styled from 'styled-components';
import CSSParallax from '../../components/CSSParallax';
import theme from '../../styles/theme';
import StarsImage from '../../assets/images/stars-white.png';

export const StyledSection = styled(CSSParallax.Section)`
  background-color: ${theme.colors.lightBlack};
  background-image: url(${StarsImage});
  background-repeat: repeat-y;
  background-position: center top;
  background-size: 100% auto;
  color: ${theme.colors.white};
  position: relative;
  z-index: 4;
`;
