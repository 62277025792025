import React, { useRef, useState } from 'react';
import { format, addHours, differenceInHours, differenceInMinutes, compareAsc } from 'date-fns';
import { TimetableType, DatesType, TimetableSession, StagesType } from '../../types/Timetable';
import { ELEMENT_SIZE, TIME_CONSTANTS } from '../../constants/timetable';
import { useInView } from 'react-intersection-observer';
import isBrowser from '../../utils/isBrowser';
import { mapOrder } from '../../utils/array';
import Block, {
  HorizontalMessage,
  VerticalMessage,
  Track,
  Header,
  Scrollable,
  Timeline,
  Row,
  Session,
  Days,
  Times,
  Sidebar,
  Column,
  Stage,
  Filter,
} from './styles';
import { get as getSessionStorage } from '../../utils/sessionStorage';
import { get as getLocalStorage } from '../../utils/localStorage';
import { default as uuid } from 'uuidv4';

const Timetable: React.FunctionComponent<TimetableType> = ({ dates, stages, stageOrder, updateArtist, showSubscribeModal }) => {
  if (!isBrowser) return null;

  const scrollContainer = useRef(null);
  const user = getSessionStorage('user');

  const [current, setCurrent] = useState(0);
  const [filter, setFilter] = useState(false);

  stages = mapOrder(stages, stageOrder, 'stage');
  dates = dates.filter((date) => date.date && date.startTime && date.endTime).sort((a: any, b: any) => a.date - b.date);

  return (
    <Block style={{ marginBottom: 64 }}>
      <Header>
        <Days>
          <DateItems dates={dates} current={current} container={scrollContainer.current} />
        </Days>
      </Header>
      <Filter
        active={filter}
        onClick={() => {
          const token = getLocalStorage('token');
          const user = getSessionStorage('user');

          if (token && user) {
            setFilter(!filter);
          } else {
            showSubscribeModal();
          }
        }}
      >
        MySplendour {filter ? 'on' : 'off'}
      </Filter>
      <Timeline>
        <Sidebar>
          <SidebarItems stages={stages} />
        </Sidebar>
        <Scrollable ref={scrollContainer}>
          <HourItems dates={dates} />
          <Sessions
            dates={dates}
            stages={stages}
            container={scrollContainer.current}
            setCurrent={setCurrent}
            updateArtist={updateArtist}
            mySplendourArtists={user && user.artists ? user.artists : []}
            filter={filter}
          />
          <HourItems dates={dates} />
        </Scrollable>
      </Timeline>
    </Block>
  );
};

const DateItems: React.FunctionComponent<{ dates: DatesType[]; container: any; current: number }> = ({ dates, container, current }): any =>
  Object.entries(dates).map(([dateKey, { date }]) => {
    const day = format(date.toString(), 'dddd').toLowerCase();
    const isCurrent = current.toString() === dateKey ? 'active' : '';

    return (
      <span key={uuid()} className={isCurrent} onClick={() => doScroll({ id: day, container })}>
        {day}
      </span>
    );
  });

const HourItems: React.FunctionComponent<{ dates: DatesType[] }> = ({ dates }): any => (
  <Times>
    {Object.values(dates).map(({ endTime, startTime }) => {
      const dayLength = differenceInHours(endTime, startTime) + 2;

      return (
        <div key={uuid()}>
          {[...Array(dayLength).keys()].map((_, hourKey) => {
            const hour = format(addHours(startTime, hourKey), 'hA');

            return <span key={uuid()}>{hour}</span>;
          })}
        </div>
      );
    })}
  </Times>
);

/**
 * Seperate stages into their primary stages and return an array of stages
 */
const SidebarItems: React.FunctionComponent<{ stages: StagesType[] }> = ({ stages }): any => {
  return stages
    .reduce((previous, stage) => {
      const name = stage.parent_stage || stage.stage;
      const count = stages.filter((item) => (item.parent_stage || item.stage) === name).length;

      // If the name isn't in the array - add it
      if (!previous.some((item) => item.name === name)) {
        return [...previous, { name, count }];
      }

      return previous;
    }, [])
    .map((stage) => (
      <Stage key={uuid()} count={stage.count}>
        <span>{stage.name}</span>
      </Stage>
    ));
};

/**
 * Sort each session by child and parent stage
 */
const Sessions: React.FunctionComponent<TimetableType & { mySplendourArtists: string[]; filter: boolean }> = ({
  dates,
  stages,
  container,
  updateArtist,
  setCurrent,
  mySplendourArtists,
  filter,
}) => {
  return (
    <div className="sessions">
      {/* Map over each day of the festival */}
      {dates.map(({ date, startTime, endTime }, dateKey) => {
        if (!startTime || !endTime) return;

        // Check if the dateis in the scroll window
        let [dateRef, onScreen] = useInView({ threshold: 0, root: container });
        if (onScreen) setCurrent(dateKey);

        // Round the session time down to the nearest hour and add and hour to give padding to the column
        let duration = Math.floor(differenceInMinutes(endTime, startTime) / TIME_CONSTANTS.ONE_HOUR) + 1;
        let day = format(date.toString(), 'dddd').toLowerCase();

        // Group stages by the primary/parent stage
        let primaryStages: Array<StagesType[]> = Object.values(groupStages(stages));

        return (
          <Column key={`date__${dateKey}`} ref={dateRef} id={day} duration={duration}>
            {/* Loop over the primary stages */}
            {primaryStages.map((childStages) => {
              if (!childStages.length) return null;

              return (
                <Row key={uuid()}>
                  {/* Loop over the child stages that share a parent stage */}
                  {childStages.map(({ sessions }) => {
                    if (!sessions.length) return null;

                    // Sort this child sessions by start time and day
                    sessions = sessions
                      .filter((session) => session.sessionDate === date && session.startTime && session.endTime)
                      .sort((a, b) => compareAsc(a.startTime, b.startTime));

                    return (
                      <Track key={uuid()}>
                        {/* Return a session for each item in the list */}
                        {sessions.map((session, sessionKey) => {
                          let previousSession = sessions[sessionKey - 1];

                          // get the time between this and the prev session otherwise the start of the day
                          let offset =
                            differenceInMinutes(session.startTime, previousSession ? previousSession.endTime : startTime) /
                            TIME_CONSTANTS.ONE_HOUR;

                          return (
                            <Artist
                              key={uuid()}
                              offset={offset}
                              updateArtist={updateArtist}
                              filter={filter}
                              mySplendourArtists={mySplendourArtists}
                              {...session}
                            />
                          );
                        })}
                      </Track>
                    );
                  })}
                </Row>
              );
            })}
          </Column>
        );
      })}
    </div>
  );
};

const Artist: React.FunctionComponent<TimetableSession> = ({ updateArtist, mySplendourArtists, ...session }) => {
  if (!session.duration || !session.artists.length) {
    return null;
  }

  let artist = session.artists[0];

  if (!artist) {
    return null;
  }

  const time = `${format(session.startTime, 'h:mm')} - ${format(session.endTime, 'h:mm')}`;
  const isVisible = session.filter ? mySplendourArtists.includes(artist.wordpress_id) : null;

  return (
    <Session isVisible={isVisible} duration={session.duration} offset={session.offset} onClick={() => updateArtist(artist)}>
      {/* <HorizontalMessage text={session.stageName} /> */}
      <span className="name">{artist.title}</span>
      <span className="time">{time}</span>
    </Session>
  );
};

/**
 * Scroll the container to the desired column
 */
const doScroll = ({ id, container }: { id: string; container: any }) => {
  if (!container || !id) return;
  const element = container.querySelector(`#${id}`);
  const left = element.offsetLeft - (isBrowser() && window.innerWidth <= 639 ? ELEMENT_SIZE.SIDEBAR_WIDTH / 1.5 : ELEMENT_SIZE.SIDEBAR_WIDTH);

  container.scroll({ left, behavior: 'smooth' });
};

/**
 * Sory an array of stages into a keyed array by their names
 */
const groupStages = (stages: StagesType[]) => {
  return stages.reduce((previous, current) => {
    const stage = current.parent_stage || current.stage;

    if (!previous.hasOwnProperty(stage)) {
      return { ...previous, [stage]: [current] };
    }

    return { ...previous, [stage]: [...previous[stage], current] };
  }, {});
};

export default Timetable;
