import React from 'react';
import ParallaxPage from '../../layout/components/ParallaxPage';
import Timetable from '../../components/Timetable';
import { OptionButton } from '../../components/ToggleButtons/styles';
import ArtistConsumer from '../../context/ArtistContext';
import HeroBlock from '../../blocks/Hero';
import PurpleSection from '../../sections/Purple';
import TallBlackSection from '../../sections/TallBlack';
import StarryBlackSection from '../../sections/StarryBlack';
import theme from '../../styles/theme';
import { MODALS } from '../../constants';
import MySplendourModal from '../../components/MySplendourModal';

class TimetablePage extends ParallaxPage {
  constructor(props) {
    super(props);
  }

  renderChildren() {
    const { content, timetable } = this.props;
    const { showModal } = this.state;

    return (
      <>
        <MySplendourModal
          updateParent={() => this.renderChildren()}
          visible={String(showModal) === MODALS.MYSPLENDOUR}
          close={() => this.setState({ showModal: false })}
        />
        {content && (
          <>
            {content.title && (
              <StarryBlackSection isFirstSection={true} pageHasAnnouncement={this.pageHasAnnouncement()}>
                <HeroBlock title={content.title} textColor={theme.colors.green} paddingTop="0">
                  <div >
                    <div className="p4" style={{ marginBottom: 32, maxWidth: 670 }}>
                      <p>Create a My Splendour account now to start planning your best festival weekend!</p>
                      <p>Just select the artists you most want to see each day and My Splendour will create you a personalised schedule.</p>
                    </div>
                    <OptionButton className="p4 text-uppercase" onClick={() => {
                      document.querySelector('#page-wrap').scrollTo({ top: 0 });
                      this.setState({ showModal: MODALS.MYSPLENDOUR });
                    }}>Sign Up Now</OptionButton>
                  </div>
                </HeroBlock>
              </StarryBlackSection>
            )}
            <TallBlackSection
              isFirstSection={!content.title}
              pageHasAnnouncement={this.pageHasAnnouncement()}
              noFooterBlocks={!content.footerBlocks || content.footerBlocks.length === 0}
            >
              <ArtistConsumer>
                {({ updateArtist }) => (
                  <Timetable
                    updateArtist={updateArtist}
                    showSubscribeModal={() => {
                      document.querySelector('#page-wrap').scrollTo({ top: 0 });
                      this.setState({ showModal: MODALS.MYSPLENDOUR });
                    }}
                    {...timetable}
                  />
                )}
              </ArtistConsumer>
              {content.footerBlocks &&
                content.footerBlocks.map((footerBlock, index) => {
                  if (index < content.footerBlocks.length - 1) {
                    return this.renderBlock(footerBlock, index);
                  }
                  return;
                })}
            </TallBlackSection>
          </>
        )}
        {content && content.footerBlocks && content.footerBlocks.length > 0 && (
          <PurpleSection>{this.renderBlock(content.footerBlocks[content.footerBlocks.length - 1])}</PurpleSection>
        )}
      </>
    );
  }
}

export default TimetablePage;
